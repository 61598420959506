import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const About = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      allFile(
        filter: { sourceInstanceName: { eq: "content" }, name: { eq: "body" } }
      ) {
        edges {
          node {
            childMarkdownRemark {
              html
              frontmatter {
                intro
                features
                outro
              }
            }
          }
        }
      }
    }
  `);

  const data = pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter;
  const features =
    pageQuery.allFile.edges[0].node.childMarkdownRemark.frontmatter.features;
  const outro = data.outro;
  const formattedOutro = outro
    .split(`\n\n`)
    .map((paragraph) => `<p>${paragraph.replace(/\n/g, `<br>`)}</p>`)
    .join(``);

  return (
    <section id="intro">
      <div className="container">
        <div className="grid">
          <div className="grid__1 pb-none grid--75">
            <h2>
              Cardiff Garden Rooms build bespoke, insulated garden rooms to suit
              your requirements.
            </h2>
          </div>
          <div className="grid__2">
            <p>{data.intro}</p>
            <p className="sub">All garden rooms include;</p>
            <ul className="leaf">
              {features.map((feat, i) => {
                return <li key={i}>{feat}</li>;
              })}
            </ul>
            <div
              dangerouslySetInnerHTML={{ __html: formattedOutro }}
              style={{ marginTop: "20px" }}
            />
          </div>
          <div className="grid__2 hide-mob">
            <StaticImage
              src="../assets/images/GardenRoomsCardiff.jpg"
              alt="Cardiff Garden Rooms"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
