import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const LogoBarWrapper = styled.div`
  width: 100%;
`;

const LogoBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
`;

const LogoBar = () => {
  return (
    <LogoBarWrapper className="bg-green">
      <LogoBarContainer className="container text-center">
        <div>
          <StaticImage
            src="../assets/images/cpa.png"
            alt="Cardiff Property Swards winner"
            width={230}
            height={113}
          />
        </div>
        <div>
          <StaticImage
            src="../assets/images/fmb.jpeg"
            alt="Federation of Master Builders"
            width={130}
            height={208}
          />
        </div>
        <div
          className="elfsight-app-e035a562-445d-40ae-8e5a-3c78c54d9434 hide-mob"
          data-elfsight-app-lazy
        ></div>
        {/* <div className="">
          <StaticImage
            src="../assets/images/cpa.png"
            alt="Cardiff Property Swards winner"
            width={230}
            height={113}
          />
        </div> */}
      </LogoBarContainer>
    </LogoBarWrapper>
  );
};

export default LogoBar;
