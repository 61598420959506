import React from "react";
import "normalize.css";
import HeadStuff from "../components/HeadStuff.js";
import Header from "../components/Header.js";
import About from "../components/About.js";
import Testimonials from "../components/Testimonials.js";
import GlobalStyles from "../styles/GlobalStyles.js";
import Gallery from "../components/Gallery.js";
import Contact from "../sections/Contact.js";
import FloatingWhatsapp from "../components/FloatingWhatsapp.js";
import { Helmet } from "react-helmet";
import LogoBar from "../components/LogoBar.js";

const index = () => {
  return (
    <>
      <HeadStuff
        title="Cardiff Garden Rooms: Bespoke and insulated ideal for home office, gym and more"
        description="Cardiff Garden Rooms build bespoke, insulated garden rooms to suit your requirements. If you are looking for a home office, gym or living space, we can help."
      />

      <GlobalStyles />
      <Header
        heroText={
          "Award Winning Garden Room Company building bespoke garden rooms ideal for home office, gym, bar, lounge and more."
        }
      />
      <About />
      <LogoBar />
      <Gallery />
      <Testimonials />
      <Contact />
      <FloatingWhatsapp />
    </>
  );
};

export default index;
